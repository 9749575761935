import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { graphql, withPrefix } from 'gatsby'
import { navigate } from '@reach/router';
import { Helmet } from 'react-helmet'

// need to install this package in your project
import paragraphs from "lines-to-paragraphs"

import Layout from '../components/Layout'

import { Link } from 'gatsby'

import { Section, Container, Columns, Hero, Heading, Content, Card, Element, Button } from 'react-bulma-components';

interface AboutPageTemplateProps {
  title: string;
  description: string;
  heading: any;
  aboutcards: any;
  details: any;
}

export const AboutPageTemplate = (props: AboutPageTemplateProps) => {
    const { title, description, heading, aboutcards, details } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>About | Model Broker</title>
            </Helmet>
            <Section>
                <Hero>
                    <Hero.Body>
                        <Container style={{ textAlign: 'center' }}>
                            <Columns>
                                <Columns.Column>
                                    <Heading>{title}</Heading>
                                    <p>{description}</p>
                                    <img src={`${withPrefix('/')}img/WorkflowMB4Diagrams.png`}
                                    width="600" height="auto" />
                                    <p>{heading.firstParagraph}</p>
                                    <br/>
                                    <p>{heading.secondParagraph}</p>
                                    <Columns.Column>

                                      <p></p>
                                      <iframe width="560" height="315"
                                      src="https://www.youtube.com/embed/ctJ66FrJ9cY"
                                      title="YouTube video player" frameBorder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                      
                                    </Columns.Column>
                                </Columns.Column>
                            </Columns>
                        </Container>
                    </Hero.Body>
                </Hero>
            </Section>
            <Section size="medium" className="second-section">
                <Container>
                    <Columns>
                        <Columns.Column>
                            <Card>
                                <Card.Content>
                                    <Content>
                                        <Element style={{ textAlign: 'center' }}>
                                            <img src={`${withPrefix('/')}img/pdf.svg`}></img>
                                            <Heading>{aboutcards.sourcematerial.title}</Heading>
                                        </Element>
                                        {aboutcards.sourcematerial.description}
                                        <Element style={{ textAlign: 'center', marginTop: '20px' }}>
                                            <Button
                                              color="primary"
                                              rounded outlined={true}
                                              renderAs={Link} to={aboutcards.sourcematerial.link}
                                              >
                                              Read more
                                            </Button>
                                        </Element>
                                    </Content>
                                </Card.Content>
                            </Card>
                        </Columns.Column>
                        <Columns.Column>
                            <Card>
                                <Card.Content>
                                    <Content>
                                        <Element style={{ textAlign: 'center' }}>
                                            <img src={`${withPrefix('/')}img/config.svg`}></img>
                                            <Heading>{aboutcards.configuration.title}</Heading>
                                        </Element>
                                        {aboutcards.configuration.description}
                                        <Element style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <Button
                                          color="primary"
                                          rounded outlined={true}
                                          renderAs={Link} to={aboutcards.configuration.link}
                                          >
                                          Read more
                                        </Button>
                                        </Element>
                                    </Content>
                                </Card.Content>
                            </Card>
                        </Columns.Column>
                        <Columns.Column>
                            <Card>
                                <Card.Content>
                                    <Content>
                                        <Element style={{ textAlign: 'center' }}>
                                            <img src={`${withPrefix('/')}img/target.svg`}></img>
                                            <Heading>{aboutcards.targetmodel.title}</Heading>
                                        </Element>
                                        {aboutcards.targetmodel.description}
                                        <Element style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <Button
                                          color="primary"
                                          rounded outlined={true}
                                          renderAs={Link} to={aboutcards.targetmodel.link}
                                          >
                                          Read more
                                        </Button>
                                        </Element>
                                    </Content>
                                </Card.Content>
                            </Card>
                        </Columns.Column>
                    </Columns>
                </Container>
            </Section>
            <Section id="sourcematerial">
                <Container>
                    <Columns>
                        <Columns.Column size={5} offset={1}>
                            <Heading>{details.sourcematerial.title}</Heading>
                            <div dangerouslySetInnerHTML={{ __html: paragraphs(details.sourcematerial.content)}} />
                            <br/>
                        </Columns.Column>
                        <Columns.Column size={5}>
                            <Zoom zoomMargin={50}>
                                <img src={`${withPrefix('/' + details.sourcematerial.imagePath)}`} />
                            </Zoom>
                        </Columns.Column>
                    </Columns>
                </Container>
            </Section>
            <Section id="configuration">
                <Container>
                    <Columns>
                        <Columns.Column size={5} offset={1}>
                            <Zoom zoomMargin={50}>
                                <img src={`${withPrefix('/' + details.configuration.imagePath)}`} />
                            </Zoom>
                        </Columns.Column>
                        <Columns.Column size={5}>
                            <Heading>{details.configuration.title}</Heading>
                            <div dangerouslySetInnerHTML={{ __html: paragraphs(details.configuration.content)}} />
                            <br/>
                        </Columns.Column>
                    </Columns>
                </Container>
            </Section>
            <Section id="targetmodel">
                <Container>
                    <Columns>
                        <Columns.Column size={5} offset={1}>
                            <Heading>{details.targetmodel.title}</Heading>
                            <div dangerouslySetInnerHTML={{ __html: paragraphs(details.targetmodel.content)}} />
                            <br/>
                        </Columns.Column>
                        <Columns.Column size={5}>
                            <Zoom zoomMargin={50}>
                                <img src={`${withPrefix('/' + details.targetmodel.imagePath)}`} />
                            </Zoom>
                        </Columns.Column>
                    </Columns>
                </Container>
            </Section>
            <Section id="screenshots">
                <Container>
                    <Element style={{ textAlign: 'center', marginBottom: '50px' }}>
                        <Heading>{details.screenshots.title}</Heading>
                          </Element>
                              <Columns>
                                <Columns.Column size={3} offset={1}>
                                    <h2>{details.screenshots.snapshot1.description}</h2>
                                    <Zoom zoomMargin={50}>
                                        <img src={`${withPrefix('/' + details.screenshots.snapshot1.imagePath)}`} />
                                    </Zoom>
                                </Columns.Column>
                                <Columns.Column size={3} offset={1}>
                                    <h2>{details.screenshots.snapshot2.description}</h2>
                                    <Zoom zoomMargin={50}>
                                        <img src={`${withPrefix('/' + details.screenshots.snapshot2.imagePath)}`} />
                                    </Zoom>
                                </Columns.Column>
                                <Columns.Column size={3} offset={1}>
                                    <h2>{details.screenshots.snapshot3.description}</h2>
                                    <Zoom zoomMargin={50}>
                                        <img src={`${withPrefix('/' + details.screenshots.snapshot3.imagePath)}`} />
                                    </Zoom>
                                </Columns.Column>
                              </Columns>
                        </Container>
                      </Section>
        </React.Fragment>
    )
}

interface AboutPageProps {
    data?: any;
}

const AboutPage = (props: AboutPageProps) => {
    const { data } = props;
    const { markdownRemark: post } = data
    const aboutPageTemplateProps: AboutPageTemplateProps = post.frontmatter;
    return (
        <Layout isIndex={false}>
            <AboutPageTemplate {...aboutPageTemplateProps} />
        </Layout>
    )
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        aboutcards {
            configuration {
            title
            description
            link
            }
            sourcematerial {
            title
            description
            link
            }
            targetmodel {
            title
            description
            link
            }
        }
        heading {
            image
            firstParagraph
            secondParagraph
        }
        details {
            sourcematerial {
                title
                imagePath
                content
            }
            configuration {
                title
                imagePath
                content
            }
            targetmodel {
                title
                imagePath
                content
            }
            screenshots {
                title
                snapshot1{
                  description
                  imagePath
                }
                snapshot2{
                  description
                  imagePath
                }
                snapshot3{
                  description
                  imagePath
                }
            }
        }
      }
    }
  }
`
